
import React from 'react'
import { Helmet } from 'react-helmet'


export default function Meta({
	title,
	description = "Create an invite-only chat rooms where every message is anonymous but everybody knows who joined the room, they just don't know who said what.",
}) {

	const fullTitle = !title
		?	"Nameless - Chat Anonymously With People You Know"
		:	`${title} - Nameless Chat`

	return <Helmet>

		<title>{ fullTitle }</title>

		<meta name='description' content={description} />

		<meta name='og:title' content={fullTitle} />
		<meta name='og:description' content={description} />
		<meta name='og:type' content='website' />
		<meta name='og:image' content='https://nameless.im/banner.jpg' />
		<meta name='og:image:width' content='1200' />
		<meta name='og:image:height' content='630' />

		<meta name='twitter:card' content='summary' />
		<meta name='twitter:site' content='@NamelessChat' />

		<meta name='application-name' content='Nameless' />
		<meta name='theme-color' content='#FFCA28' />
		<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />

	</Helmet>
}